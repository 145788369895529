







































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import PASSWORD_FORGOT from '@/graphql/mutations/PasswordForgot.graphql';
import { required, email } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import {
  PasswordForgotInput,
  PasswordForgotMutationVariables,
} from '@/types/schema';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'ForgotPassword',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        email: '',
      } as PasswordForgotInput,
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
      },
    };
  },
  methods: {
    validationMessages,
    async forgotPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: PASSWORD_FORGOT,
          variables: {
            input: {
              email: this.form.email,
            },
          } as PasswordForgotMutationVariables,
        });
        this.$router.push({ name: 'login', query: { forgot: '1' } });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
});
